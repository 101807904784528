import React from 'react';

export type CookieBannerProps = {
  title: string;
  description: string;
  acceptLabel: string;
  declineLabel: string;
  onAccept: () => void;
  onDecline: () => void;
};

export function CookieBanner({
  title,
  description,
  acceptLabel,
  declineLabel,
  onAccept,
  onDecline,
}: CookieBannerProps) {
  return (
    <div
      role="dialog"
      aria-labelledby="cookieBannerTitle"
      aria-describedby="cookieBannerText"
      className="flex flex-col justify-center gap-32px border-t border-black bg-white p-32px lg:flex-row lg:items-end"
    >
      <div className="flex flex-col">
        <h2 id="cookieBannerTitle" className="font-bold text-sm text-black">
          {title}
        </h2>
        <p id="cookieBannerText" className="text-sm text-black">
          {description}
        </p>
      </div>

      <div className="flex flex-col items-center justify-center gap-y-24px lg:flex-row lg:gap-x-32px">
        <button
          onClick={onDecline}
          className="px-24px py-14px font-medium text-lg text-fiber-neutral-900 bg-white border border-fiber-neutral-300 rounded-md whitespace-nowrap"
        >
          {declineLabel}
        </button>
        <button
          onClick={onAccept}
          className="px-24px py-14px font-medium text-lg text-fiber-grey-900 bg-yellow rounded-md whitespace-nowrap"
        >
          {acceptLabel}
        </button>
      </div>
    </div>
  );
}
