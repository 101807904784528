import containerQueriesPlugin from '@tailwindcss/container-queries';

const figmaColors = {
  fiber: {
    masterbrand: {
      white: 'white',
      black: 'black',
    },
    grey: {
      900: '#202020',
      800: '#414141',
      700: '#606060',
      600: '#747474',
      500: '#9c9c9c',
      400: '#bcbcbc',
      300: '#dfdfdf',
      200: '#ededed',
      100: '#f4f4f4',
      50: '#fafafa',
    },
    teal: {
      900: '#005649',
      800: '#006e5d',
      700: '#008571',
      600: '#009d85',
      500: '#33b19d',
      400: '#59bfb0',
      300: '#80cec2',
      200: '#a6ddd4',
      100: '#ccebe7',
      50: '#f2faf9',
    },
    red: {
      900: '#8c1d20',
      800: '#b32529',
      700: '#d92d31',
      600: '#ff353a',
      500: '#ff5d61',
      400: '#ff7c7f',
      300: '#ff9a9d',
      200: '#ffb8ba',
      100: '#ffd7d8',
      50: '#fff5f5',
    },
    yellow: {
      900: '#806107',
      800: '#b3880a',
      700: '#d9a50c',
      600: '#ffc20e',
      500: '#ffce3e',
      400: '#ffd762',
      300: '#ffe187',
      200: '#ffeaab',
      100: '#fff3cf',
      50: '#fffcf3',
    },
    blue: {
      900: '#185173',
      800: '#1e6792',
      700: '#257db2',
      600: '#2b93d1',
      500: '#55a9da',
      400: '#75b9e1',
      300: '#95c9e8',
      200: '#b5d9ef',
      100: '#d5e9f6',
      50: '#f4fafd',
    },
    purple: {
      900: '#4b366c',
      800: '#5e4487',
      700: '#7151a2',
      600: '#7d5ab4',
      500: '#977bc3',
      400: '#ab94ce',
      300: '#beadda',
      200: '#d2c5e5',
      100: '#e5def0',
      50: '#f9f7fb',
    },
    orange: {
      900: '#7e3510',
      800: '#9d410f',
      700: '#b94c15',
      600: '#e87130',
      500: '#f68855',
      400: '#f89876',
      300: '#fab097',
      200: '#fbc8b6',
      100: '#fce0d5',
      50: '#fff7f4',
    },
    skyblue: {
      900: '#2f7786',
      800: '#3b95a8',
      700: '#47b2ca',
      600: '#4fc6e0',
      500: '#72d1e6',
      400: '#8ddaeb',
      300: '#a7e3f0',
      200: '#c1ebf4',
      100: '#dcf4f9',
      50: '#f6fcfd',
    },
    green: {
      900: '#29663a',
      800: '#34824a',
      700: '#3f9d59',
      600: '#4ab969',
      500: '#6ec787',
      400: '#89d29e',
      300: '#a5dcb4',
      200: '#c0e7cb',
      100: '#dbf1e1',
      50: '#f6fcf8',
    },
    pink: {
      900: '#916676',
      800: '#b68094',
      700: '#da99b1',
      600: '#f2aac5',
      500: '#f5bbd1',
      400: '#f7c8d9',
      300: '#f9d5e2',
      200: '#fae1eb',
      100: '#fceef3',
      50: '#fefbfc',
    },
    neutral: {
      900: '#101828',
      800: '#1d2939',
      700: '#344054',
      600: '#475467',
      500: '#667085',
      400: '#98a2b3',
      300: '#d0d5dd',
      200: '#e4e7ec',
      100: '#f2f4f7',
      50: '#f9fafb',
    },
  },
  'surface-variant': {
    light: {
      DEFAULT: '#f2f4f7',
      on: '#344054',
      outline: '#d0d5dd',
    },
    dark: {
      DEFAULT: '#344054',
      on: '#e4e7ec',
      outline: '#98a2b3',
    },
  },
};

const figmaShadows = {
  base: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  'x-small':
    '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  small:
    '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
  medium:
    '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
  large:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
  'x-large': '0px 24px 48px -12px rgba(16, 24, 40, 0.25)',
  '2x-large': '0px 32px 64px -12px rgba(16, 24, 40, 0.2)',
};

const customColors = {
  white: '#ffffff',
  black: '#231f20',
  green: {
    DEFAULT: '#009d85',
  },
  yellow: {
    DEFAULT: '#ffc20e',
  },
  blue: {
    dark: '#076e95',
    DEFAULT: '#4fc6e0',
    light: '#def6fb',
  },
  red: {
    DEFAULT: '#ef4044',
  },
  gray: {
    dark: '#666666',
    DEFAULT: '#999999',
    light: '#f7f7f7',
  },
};

const screenSizes = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px', // only for header size purposes - do not use for media query
};

module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    colors: customColors,
    textColor: customColors,
    backgroundColor: customColors,
    borderColor: customColors,
    stroke: customColors,

    // Removing the 2xl screen size (1536px)
    // See: https://tailwindcss.com/docs/container
    container: {
      screens: screenSizes,
    },

    maxHeight: {
      0: '0',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
    },
    maxWidth: {
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
    },
    extend: {
      animation: {
        'fade-in': 'fadein 1s linear forwards',
      },
      keyframes: {
        fadein: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
      },
      colors: figmaColors,
      textColor: figmaColors,
      backgroundColor: figmaColors,
      borderColor: figmaColors,
      stroke: figmaColors,
      boxShadow: figmaShadows,
      dropShadow: figmaShadows,
      gap: {
        '24px': '24px',
        '32px': '32px',
        '40px': '40px',
      },
      padding: {
        '14px': '14px',
        '24px': '24px',
        '32px': '32px',
      },
    },
    fontFamily: {
      sans: ['Helvetica Neue', 'sans-serif'],
      serif: ['Meta Serif Pro Book', 'serif'],
    },
  },
  plugins: [containerQueriesPlugin],
  figma: { colors: figmaColors },
};
